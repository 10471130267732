<!-- 简历详情页面 -->
<template>
  <div class="box">
    <div class="wrap">
      <!-- 基本信息 -->
      <div style="overflow: hidden">
        <div class="img-wrap">
          <img :src="context.photo" alt class="weizhi" />
        </div>

        <div class="all-base">
          <div class="name">{{ context.name }}</div>
          <div class="base">
            <!-- <span>
              {{ context.years_old + "岁" }}
            </span> -->
            <!-- <i></i> -->
            <span>
              <!-- {{
                data.resume_education_experience_list[0].education_background
              }} -->
              <!-- 学历 -->
            </span>
          </div>
          <div class="expect">
            <p>
              年龄：
              <span>
                {{ context.years_old + "岁" }}
              </span>
            </p>
            <p>
              部门：
              <span>
                {{ context.dp_name }}
              </span>
            </p>
            <p>
              上级：
              <span style="margin-right: 7px">
                {{ context.parent_name }}
              </span>
            </p>
            <p>
              岗位：
              <span style="margin-right: 7px">
                {{ context.positon }}
              </span>
            </p>
            <p>
              邮箱：
              <span>
                {{ context.email }}
              </span>
            </p>
            <p>
              性别：
              <span>
                {{ context.gender === 0 ? "男" : "女" }}
              </span>
            </p>
            <p>
              手机号：
              <span>
                {{ context.mobile }}
              </span>
            </p>
            <p>
              民族：
              <span>
                {{ context.nation === "" ? "未填写" : context.nation }}
              </span>
            </p>
            <p>
              身份证：
              <span>
                {{ context.id_number }}
              </span>
            </p>
            <p>
              现住址：
              <span>
                {{ context.place }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { staff_detail } from "@/api/me/index";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      xinXi: this.$route.query.item,
      context: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    xiangqing() {
      var obj = {
        user_id: this.xinXi.user_id,
        lie_token: sessionStorage.getItem("lie_token"),
      };
      staff_detail(obj).then((res) => {
        if (res.code === "0") {
          this.context = res.context;
        }
      });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.xiangqing();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.weizhi {
  border: 1px solid #ccc;
}
.box {
  @include padding;
  min-width: 1100px;
  min-height: 70vh;
  padding-bottom: 20px;
}
.wrap {
  padding: 20px 50px;
  min-height: 80vh;
  // overflow: auto;
  // height: calc(90vh - 100px);
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: $box-shadow;
}
.get-btn-wrap {
  width: 100%;
  height: 50px;
}
.get-btn {
  float: right;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

/* 头像 */
.img-wrap {
  width: 110px;
  height: 110px;
  float: left;
}
.img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.all-base {
  float: left;
  margin-left: 120px;
}
.name {
  font-size: 28px;
  color: $jd_fontColor;
  margin-bottom: 10px;
}
.base {
  font-size: $jd_fontSize;
  color: $selfColor;
}
/* 基本信息间隔 */
i {
  margin: 0 15px;
  border-right: 1px solid #ccc;
}
// 标签

.tag span {
  width: 80px;
  height: 30px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
  line-height: 30px;
  background-color: $main_bc;
  font-size: $jd_fontSize;
  color: $main_color;
}
// 期望
.expect p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
}
.expect p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
}
.work,
.edu,
.self {
  margin-top: 39px;
}
.work {
  clear: both;
}
.company-name {
  font-size: 18px;
  padding-left: 10px !important;
  color: $jd_fontColor;
}
.work p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
  padding: 0 30px;
  margin: 10px 0 0 0;
}
.work span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.edu p {
  padding: 0 30px;
}
.edu p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.self-word {
  font-size: $jd_fontSize;
  color: $selfColor;
  padding: 0px 30px;
}
</style>
